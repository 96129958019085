import React, { useState } from "react";
// import Chainsafer from "../components/chainsafer/chainsafer";
// import FAQ from "../components/faq/faq";
// import Features from "../components/features/features";
// import MarketMakers from "../components/marketMakers/marketMakers";
// import Tiers from "../components/tiers/tiers";
// import Tokenomics from "../components/tokenomics/tokenomics";
// import Welcome from "../components/welcome/welcome";
// import CountDown from "../components/ComingSoon/";
import { Switch, Route } from "react-router-dom";
// import RewardsPage from "./rewardsPage/rewardsPage";
// import LockerPage from "./lockerPage/lockerPage";
// import DetailPage from "./detailPage/detailPage";
// import Game from "../components/game/Game";
// import PoolDetail from "../pages/PoolDetail/poolDetail";
// import WalletConnect from "../pages/walletConnect/walletConnect";
// import PoolScreenPage from "./poolScreen/poolScreen";
// import TransactionPage from "./transactionPage/transaction";
// import DepositeWithdrawPage from "./depositeWithdraw/depositeWithdraw";
import Fista from "../pages/PoolDetail/Fista";
import PoolList from "../pages/PoolDetail/PoolList";
import NewPoolList from "../pages/PoolDetail/NewPoolList";
import Swap from "../pages/PoolDetail/Swap";


const HomePage = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Fista />
          
        </Route>
        <Route exact path="/list">
          <NewPoolList />
        </Route>
        <Route exact path="/swap">
          <Swap />
        </Route>
        {/* <Route exact path="/deposit">
          <RewardsPage />
        </Route>
        <Route exact path="/locker">
          <LockerPage />
        </Route>
        <Route exact path="/pool">
          <DetailPage />
        </Route>
        <Route exact path="/game">
          <Game />
        </Route>
        <Route exact path="/poolDetail">
          <PoolDetail />
        </Route>
        <Route exact path="/WalletConnect">
          <WalletConnect />
        </Route>
        <Route exact path="/pool_screen">
          <PoolScreenPage />
        </Route>
        <Route exact path="/staking">
          <TransactionPage />
        </Route>
        <Route exact path="/depositeWithdraw">
          <DepositeWithdrawPage />
        </Route> */}
      </Switch>

    </>
  );
};

export default HomePage;

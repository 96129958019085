import React, { useState } from "react";
import { Row, Col, Container,Button,ModalHeader,ModalFooter, Modal, ModalBody } from "reactstrap";
import Navigation from "../../components/navigation/navigationPoolDetail";
import styles from "./poolDetail.module.scss";
 

 
import SwapCard from "./SwapCard.js"

import Web3 from "web3"
 
import TOKEN_ABI from  "../../BlockchainProvider/ABI/TOKEN_ABI.json"
import SWAP_ABI from  "../../BlockchainProvider/ABI/SWAP_ABI.json"


import Allocation from "../allocationPage/allocationPage";
 
import Schedule from "../schedulePage/shedulePage";
import ProjectDetail from "../ProjectDetail/ProjectDetail";
import { useEffect } from 'react';
import {useWallet} from '@binance-chain/bsc-use-wallet'


const Pages = {
  Allocation: <Allocation />,
  Schedule: <Schedule />,
  // Pools: <PoolsPage />,
  Project_Detail : <ProjectDetail />
};
 


 


const Swap = () => {
  
 

const wallet = useWallet();

const nautSwapContract = "0xC2D55A59C044b902325638e0d0C132f4E1ab887e" ;
// const nautLPSwapContract = "0x48EA0Beb604a13159b7719b2A7D0741b333C4a01" ;
 
const [data, setData]= useState([]);
const [camount, setCamount]= useState(0);
  
const [balanceModal, setbalanceModal] = useState(false);

const toggleBalanceModal = () => setbalanceModal(!balanceModal);


let web3Provider  = window.ethereum ; 


useEffect(()=>{

        
    'use strict';
    
function Tabs() {
var bindAll = function() {
var menuElements = document.querySelectorAll('[data-tab]');
for(var i = 0; i < menuElements.length ; i++) {
  menuElements[i].addEventListener('click', change, false);
}
}

var clear = function() {
var menuElements = document.querySelectorAll('[data-tab]');
for(var i = 0; i < menuElements.length ; i++) {
  menuElements[i].classList.remove('active');
  var id = menuElements[i].getAttribute('data-tab');
  document.getElementById(id).classList.remove('active');
}
}

var change = function(e) {
clear();
e.target.classList.add('active');
var id = e.currentTarget.getAttribute('data-tab');
document.getElementById(id).classList.add('active');
}

bindAll();
}

 


var connectTabs = new Tabs();






},[])


 
useEffect(() => {
   
   
},[wallet.account])
  
  

return (
  
  
  <div className={styles.body}>
      
 
      <section id="sec-new">
      <div className="container">
           {/* <div className="wrp-inactive">
               <div className="child-inactive1">
                   <button data-tab="naut" className="b-nav-tab active metric-s">NAUT</button>
               </div>
               <div className="child-inactive1 mart-f">
                   <button data-tab="nautlp" className="b-nav-tab metric-s">NAUT LP</button>
               </div>
           </div> */}
       </div>
       <div className="container">
       {/* <div id="naut" className="b-tab active"> */}
              <SwapCard swapContract={nautSwapContract}  />
       {/* </div> */}
       <div id="nautlp" className="b-tab active">
       {/* <SwapCard swapContract={nautLPSwapContract}  /> */}

       </div>
       </div>
   </section>
 

  </div>
);
}
export default Swap;

 
import React, { Component } from "react"
import { Col, Row, Container } from "reactstrap";

import styles from "../footer/footer.module.scss";
import Countdown from "react-countdown";

// "use strict";

export default class CountDown extends Component {

  constructor(props) {
      super(props);
      this.state = {
          date:'',
          timer: Date.now(),
          days:'', month:'', year: '', hour:'',
          ticking:false,
      }
  }
  
  componentDidMount() {
      this.getTime();

      var canvas = document.getElementById('canvas'),
      ctx = canvas.getContext('2d'),
      w = canvas.width = window.innerWidth,
      h = canvas.height = window.innerHeight,
            
      hue = 245,
      stars = [],
      count = 0,
      maxStars = 1400;

    // Thanks @jackrugile for the performance tip! https://codepen.io/jackrugile/pen/BjBGoM
    // Cache gradient
    var canvas2 = document.createElement('canvas'),
    ctx2 = canvas2.getContext('2d');
    canvas2.width = 100;
    canvas2.height = 100;
    var half = canvas2.width/2,
    gradient2 = ctx2.createRadialGradient(half, half, 0, half, half, half);
    gradient2.addColorStop(0.025, '#fff');
    gradient2.addColorStop(0.1, 'hsl(' + hue + ', 61%, 33%)');
    gradient2.addColorStop(0.25, 'hsl(' + hue + ', 64%, 6%)');
    gradient2.addColorStop(1, 'transparent');

    ctx2.fillStyle = gradient2;
    ctx2.beginPath();
    ctx2.arc(half, half, half, 0, Math.PI * 2);
    ctx2.fill();

    var Star = function() {

      this.orbitRadius = random(maxOrbit(w,h));
      this.radius = random(60, this.orbitRadius) / 12;
      this.orbitX = w / 2;
      this.orbitY = h / 2;
      this.timePassed = random(0, maxStars);
      this.speed = random(this.orbitRadius) / 400000;
      this.alpha = random(2, 10) / 10;
    
      count++;
      stars[count] = this;
    }
    
    Star.prototype.draw = function() {
      var x = Math.sin(this.timePassed) * this.orbitRadius + this.orbitX,
          y = Math.cos(this.timePassed) * this.orbitRadius + this.orbitY,
          twinkle = random(10);
    
      if (twinkle === 1 && this.alpha > 0) {
        this.alpha -= 0.05;
      } else if (twinkle === 2 && this.alpha < 1) {
        this.alpha += 0.05;
      }
    
      ctx.globalAlpha = this.alpha;
        ctx.drawImage(canvas2, x - this.radius / 2, y - this.radius / 2, this.radius, this.radius);
      this.timePassed += this.speed;
    }
    
    for (var i = 0; i < maxStars; i++) {
      new Star();
    }    

    function maxOrbit(x,y) {
      var max = Math.max(x,y),
          diameter = Math.round(Math.sqrt(max*max + max*max));
      return diameter/2;
    }
    
    function random(min, max) {
      if (arguments.length < 2) {
        max = min;
        min = 0;
      }
      
      if (min > max) {
        var hold = max;
        max = min;
        min = hold;
      }
    
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function animation() {
      ctx.globalCompositeOperation = 'source-over';
      ctx.globalAlpha = 0.8;
      ctx.fillStyle = 'hsla(' + hue + ', 64%, 6%, 1)';
      ctx.fillRect(0, 0, w, h)
    
    ctx.globalCompositeOperation = 'lighter';
    for (var i = 1, l = stars.length; i < l; i++) {
      stars[i].draw();
    };  
    
    window.requestAnimationFrame(animation);
  }

  animation();
  
}

  getTime=async()=>{
    let timer = this.state.timer;
    var date = new Date("2021-05-01T06:00:00+0000");
    var milliseconds = date.getTime();
    console.log(milliseconds);
    timer = milliseconds;
    await this.setState({timer:timer, ticking:true, date:new Date(timer).toUTCString()});
  }

  tick=(e)=>{
    console.log(e);
    localStorage.setItem('mili', Date.now()+e.total);
  }


  render() {
    return (
          <div className={styles.footer}>
            <section className={styles.sectionTimer}>
            <canvas id="canvas" className={styles.canvas}></canvas>
              <Container className={styles.containerTimer}>
                <Row className="justify-content-center">
                  <Col xs="12" className={styles.timerText}>
                    COUNTDOWN TO THE NEXT LAUNCH
                  </Col>
                  <Col xs="12" className={styles.timerText2}>
                    LAUNCHING IDO PLATFORM
                  </Col>
                  <Col md={5} lg={4} sm={6} xs={12}>
                    <div className={styles.center}>
                      {this.state.ticking &&
                        <Countdown date={this.state.timer} 
                        onTick={this.tick}
                        />
                      }
                    </div>
                  </Col>
                  <Col xs="12" className={styles.DateAndTime}>
                    <div>{this.state.date}</div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
  )}

}
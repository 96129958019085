import React, { useState } from "react";
import { Row, Col, Container,Button,ModalHeader,ModalFooter, Modal, ModalBody } from "reactstrap";
import Navigation from "../../components/navigation/navigationPoolDetail";
import styles from "./poolDetail.module.scss";
 
/*

var num = 10e18;
var parts = 3000 ;
var equalParts = num/parts ;
var a = [] ;
for(var i = 0 ; i < 3000 ; i++ ){
    if(i % 2 == 0){
    a.push(Math.random()*equalParts + equalParts)    
  }
else{
    a.push(equalParts - Math.random()*equalParts)
}
}
console.log(a);
var myTotal = 0 
for(var i = 0, len = a.length; i < len; i++) {
    myTotal += a[i];  // Iterate over your first array and then grab the second element add the values up
}

console.log(myTotal)



*/
 
import PoolRow2 from "./PoolRow2.js"  

 
import NEW_USERS from  "../../BlockchainProvider/ABI/NEW_USERS.json"
import TOKEN_POOL_ABI from  "../../BlockchainProvider/ABI/TOKEN_POOL.json"
import TOKEN_ABI from  "../../BlockchainProvider/ABI/TOKEN_ABI.json"
import AIRDROP from  "../../BlockchainProvider/ABI/AIRDROP.json"
import Web3 from "web3"

 
import Allocation from "../allocationPage/allocationPage";
 
import Schedule from "../schedulePage/shedulePage";
import ProjectDetail from "../ProjectDetail/ProjectDetail";
import { useEffect } from 'react';
import {useWallet} from '@binance-chain/bsc-use-wallet'


const Pages = {
  Allocation: <Allocation />,
  Schedule: <Schedule />,
  // Pools: <PoolsPage />,
  Project_Detail : <ProjectDetail />
};
 

 
// const airdropContarct = '0x144f9df76dF3f0a5a2f0DC5B8202e49D1Be3CEC8' ;
const airdropContarct = '0x455C4ec120864a090A47e22608aAE482623BC7c2' ;




const NewPoolList = () => {
  
 
  let web3Provider  = window.ethereum ; 

const wallet = useWallet();
 
const [modal, setModal] = useState(false);

const toggle = () => setModal(!modal);
 
const [users, setUsers] = useState([]);
const [usersD, setUsersD] = useState([]);
const [total, setTotal] = useState(0);
const [totalD, setTotalD] = useState(0);

const [amounts, setAmounts] = useState([]);
 

useEffect(()=>{

        
    'use strict';
    
function Tabs() {
var bindAll = function() {
var menuElements = document.querySelectorAll('[data-tab]');
for(var i = 0; i < menuElements.length ; i++) {
  menuElements[i].addEventListener('click', change, false);
}
}

var clear = function() {
var menuElements = document.querySelectorAll('[data-tab]');
for(var i = 0; i < menuElements.length ; i++) {
  menuElements[i].classList.remove('active');
  var id = menuElements[i].getAttribute('data-tab');
  document.getElementById(id).classList.remove('active');
}
}

var change = function(e) {
clear();
e.target.classList.add('active');
var id = e.currentTarget.getAttribute('data-tab');
document.getElementById(id).classList.add('active');
}

bindAll();
}

 


var connectTabs = new Tabs();






},[])


 
useEffect(() => {
  if(window.ethereum){
    web3Provider  = window.ethereum;
  }
  else{
    web3Provider = new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')
   
  }
  gettUsers()

},[wallet.account])
 
const gettUsers = async () => {

  let _web3 = new Web3(web3Provider); 
  let _airdropContract = new _web3.eth.Contract(AIRDROP,airdropContarct);
  let _users = [] ;
  let _amounts = [] ;
  let _usersD = [] ;
  var num = 10e18;
  var parts = 3000 ;
  var equalParts = num/parts ;
  var _total = 0 ;
  var _totalD = 0 ;
  
  NEW_USERS.map(async (value, index) => {
      let temp = {} ;
      // console.log(value.user);
      // let dropped = await _airdropContract.methods.getAmount(value.user).call() ;
      let added = await _airdropContract.methods.getAmount(value.user).call() ;
       
        // let airdroppedtoken = await _airdropContract.methods.coinA().call() ;
        // let _airdropTokenContract = new _web3.eth.Contract(TOKEN_ABI,airdroppedtoken);
        // let _airdropTokenDecimals = await _airdropTokenContract.methods.decimals().call() ;      
        // let _amount = parseFloat(userInfo.amount/1e1**stotalDecimals).toFixed() ;
        let _amount = 0 ;
        if(index % 2 == 0){
          _amount = Math.random()*equalParts + equalParts ;   
        }
        else{
            _amount = equalParts - Math.random()*equalParts ;
         }
        _amount = parseFloat(_amount).toFixed() ;
        if(_amount == 0){
          alert("Error! Try Again.")
        }
        if(added == 0 ){
          _total += parseFloat(_amount)
          _users.push(value.user) ;
          _amounts.push(_amount) ;
        }
        if(added > 0 ){
          _totalD += parseFloat(added)
          _usersD.push(value.user) ;
        }

      if(index == NEW_USERS.length - 1){
        setUsers(_users);
        setAmounts(_amounts);
        setUsersD(_usersD);
        setTotal(parseFloat(_total/1e18));
        setTotalD(parseFloat(_totalD/1e18));
      }
  })
}
  

const bulkDrop = () => {
 
 
    

      let  _usersD = usersD.slice(0,100);
  console.log(_usersD);
        
  let _web3 = new Web3(web3Provider); 

  let _airdropContract = new _web3.eth.Contract(AIRDROP,airdropContarct);
  setModal(!modal)

_airdropContract.methods.paySwap(_usersD).send({
from: wallet.account
}).on('receipt', function(receipt){
setModal(modal)
gettUsers() ;

}).on('error', function(error){
console.log(error);
setModal(modal);

})
  
}

const bulkSend = () => {
      let _users = users ;
      let _amounts = amounts ;

        _users = users.slice(0,1000);
        _amounts = amounts.slice(0,1000);
     console.log(_users)
     console.log(_amounts)

      let _web3 = new Web3(web3Provider);
 
      let _airdropContract = new _web3.eth.Contract(AIRDROP,airdropContarct);
      setModal(!modal)

  _airdropContract.methods.addSwap(_users,_amounts).send({
    from: wallet.account
  }).on('receipt', function(receipt){
    _users = users.slice(1000,2000);
        _amounts = amounts.slice(1000,2000);
        console.log(_users)
        console.log(_amounts)
    _airdropContract.methods.addSwap(_users,_amounts).send({
      from: wallet.account
    }).on('receipt', function(receipt){
        _users = users.slice(2000,3000);
        _amounts = amounts.slice(2000,3000);
        console.log(_users)
        console.log(_amounts)
      _airdropContract.methods.addSwap(_users,_amounts).send({
        from: wallet.account
      }).on('receipt', function(receipt){
        setModal(modal)
        gettUsers() ;
    
      }).on('error', function(error){
        console.log(error);
        setModal(modal);
    
    })
  
    }).on('error', function(error){
      console.log(error);
      setModal(modal);
  
  })
 

  }).on('error', function(error){
    console.log(error);
    setModal(modal);

})
      
}

return (
  
  
  <div className={styles.body}>
      
 
   <section id="secod-sec" >
   <div className="container">
           <div className="wrp-inactive">
               <div className="child-inactive1">
                   <button data-tab="orange" className="b-nav-tab active metric-s">List of users</button>
               </div>
              
           </div>
       </div>
       <div className="container">
           <div id="orange" className="b-tab active">
             {/* {users.length} */}
             { 
             wallet.account  && users.length > 0 &&
           <button  className="depositButton mb-5 mr-3" onClick={bulkSend} >Bulk Airdrop Data ({total})</button>             
             } 
              { wallet.account  && usersD.length > 0 &&
           <button  className="depositButton mb-5" onClick={bulkDrop} >Bulk Airdrop Token ({totalD})</button>             
             }



             
           <div className="row  table-responsive">

               <table className="table table-dark table-hover  bg-transparent" >
                <tr>
                    <th>Sr. No.</th>
                    <th>Wallet</th>
                    <th>Added Amount</th>                   
                    <th>Dropped Amount</th>
                    <th>Airdrop</th>
                </tr>
            {NEW_USERS.length > 0 && NEW_USERS.map((value, index) => {
              // return  <PoolRow2 user={value.user}    index={index}  airdrop={airdropContarct}
                                     
                        // />

                 })}  
                 
                            </table>

           </div>
           </div>
            
       </div>
   </section> 
   
  <Modal isOpen={modal} toggle={toggle}  centered={true}>
   
        
   <ModalBody>
   <div className="modaltext">Transaction is Processing...</div>      

   </ModalBody>
   <Button className="depositButton mr-auto ml-auto mb-5" onClick={toggle}>Close</Button>
    
 </Modal>
  </div>
);
}
export default NewPoolList;

 
import React, { useState } from "react";
import { Row, Col, Container,Button,ModalHeader,ModalFooter, Modal, ModalBody } from "reactstrap";
import Navigation from "../../components/navigation/navigationPoolDetail";
import styles from "./poolDetail.module.scss";
 

 
import PoolRow from "./PoolRow.js"

import Web3 from "web3"
 
import TOKEN_ABI from  "../../BlockchainProvider/ABI/TOKEN_ABI.json"
import SWAP_ABI from  "../../BlockchainProvider/ABI/SWAP_ABI.json"


import Allocation from "../allocationPage/allocationPage";
 
import Schedule from "../schedulePage/shedulePage";
import ProjectDetail from "../ProjectDetail/ProjectDetail";
import { useEffect } from 'react';
import {useWallet} from '@binance-chain/bsc-use-wallet'


const Pages = {
  Allocation: <Allocation />,
  Schedule: <Schedule />,
  // Pools: <PoolsPage />,
  Project_Detail : <ProjectDetail />
};
 


 


const SwapCard = (props) => {
  
 

const wallet = useWallet();

const swapContract = props.swapContract ;
 
const [data, setData]= useState([]);
const [camount, setCamount]= useState(0);
const [amount, setAmount]= useState(0);

const [balanceModal, setbalanceModal] = useState(false);
const [modal, setModal] = useState(false);

const toggleBalanceModal = () => setbalanceModal(!balanceModal);
const toggleModal = () => setModal(!modal);


let web3Provider  = window.ethereum ; 


useEffect(()=>{

        
    'use strict';
    
function Tabs() {
var bindAll = function() {
var menuElements = document.querySelectorAll('[data-tab]');
for(var i = 0; i < menuElements.length ; i++) {
  menuElements[i].addEventListener('click', change, false);
}
}

var clear = function() {
var menuElements = document.querySelectorAll('[data-tab]');
for(var i = 0; i < menuElements.length ; i++) {
  menuElements[i].classList.remove('active');
  var id = menuElements[i].getAttribute('data-tab');
  document.getElementById(id).classList.remove('active');
}
}

var change = function(e) {
clear();
e.target.classList.add('active');
var id = e.currentTarget.getAttribute('data-tab');
document.getElementById(id).classList.add('active');
}

bindAll();
}

 


var connectTabs = new Tabs();






},[])


 
useEffect(() => {
  
  if(window.ethereum){
    web3Provider  = window.ethereum;
  }
  else{
    web3Provider = new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')
   
  }
  
  // console.log(wallet.account );
  getWallet()
   
},[wallet.account])
 

const setMaxDeposit = async (balance) => {
  setAmount(balance)
  setCamount(balance)


}


const handleDepositChange = (e) => {
 
    let amount =  e.target.value 
    setAmount(amount)
    setCamount(amount)
 

}



const approve = async (tokenAddress) => {
  let _web3 = new Web3(web3Provider);
  let _tokenContract = new _web3.eth.Contract(TOKEN_ABI,tokenAddress);
  let _amount = _web3.utils.toWei('10000000000000000000') ;
  setModal(!modal)

  _tokenContract.methods.approve(swapContract,_amount).send({from: wallet.account}).on('receipt', function(receipt){
    getWallet(); 
     setModal(modal);

})

.on('error', function(error, receipt) {
setModal(modal);
    
});

}

const claimToken = async () => {
  let _web3 = new Web3(web3Provider);
  let _swapContract = new _web3.eth.Contract(SWAP_ABI,swapContract);
  setModal(!modal)

  _swapContract.methods.claimnewToken().send({from: wallet.account}).on('receipt', function(receipt){
    getWallet(); 
     setModal(modal);

})

.on('error', function(error, receipt) {
setModal(modal);
    
});
}

const deposit = async () => {
  let _web3 = new Web3(web3Provider);
  let _swapContract = new _web3.eth.Contract(SWAP_ABI,swapContract);
  let _getTokenA = await _swapContract.methods.naut().call() ;
  let _tokenAContract = new _web3.eth.Contract(TOKEN_ABI,_getTokenA);
  let _getTokenABalance = await _tokenAContract.methods.balanceOf(wallet.account).call() ;
  _getTokenABalance = parseFloat(_getTokenABalance).toFixed() ;
  _getTokenABalance = parseInt(_getTokenABalance) ;
  let _getTokenADecimals = await _tokenAContract.methods.decimals().call() ;
  let _amount = parseFloat(amount*1e1**_getTokenADecimals).toFixed() ;
  _amount = parseInt(_amount) ;
  console.log(_getTokenABalance);
  console.log(_amount);
  console.log(_getTokenABalance < _amount);
  if(_getTokenABalance == 0 || _getTokenABalance < _amount ){
    setbalanceModal(!balanceModal)
    return false;
  }
  setModal(!modal);

  _swapContract.methods.submitToken(_amount).send({from: wallet.account}).on('receipt', function(receipt){
    getWallet(); 
     setModal(modal);
     setCamount(0);
     setAmount(0);

})

.on('error', function(error, receipt) {
setModal(modal);
    
});

}

const getWallet = async () => {
    let _web3 = new Web3(web3Provider);
    let _swapContract = new _web3.eth.Contract(SWAP_ABI,swapContract);

    let _getTokenA = await _swapContract.methods.naut().call() ;
    let _getTokenB = await _swapContract.methods.newtoken().call() ;

    let _tokenAContract = new _web3.eth.Contract(TOKEN_ABI,_getTokenA);
    let _tokenBContract = new _web3.eth.Contract(TOKEN_ABI,_getTokenB);

    let _getTokenASymbol = await _tokenAContract.methods.symbol().call() ;
    let _getTokenBSymbol = await _tokenBContract.methods.symbol().call() ;

    let _getTokenADecimals = await _tokenAContract.methods.decimals().call() ;
    let _getTokenBDecimals = await _tokenBContract.methods.decimals().call() ;
    let _getTokenABalance = 0 ;
    let _getTokenBBalance = 0 ;
    let claimable = 0 ;
    var temp = {} ;
    // temp['claimOn'] = false;
    if(wallet.account != null){
        _getTokenABalance = await _tokenAContract.methods.balanceOf(wallet.account).call() ;
        _getTokenBBalance = await _tokenBContract.methods.balanceOf(wallet.account).call() ;
        claimable = await _swapContract.methods.holders(wallet.account).call() ;
        claimable = claimable/1e1**_getTokenADecimals ;
        temp['approval'] = await _tokenAContract.methods.allowance(wallet.account, swapContract).call() ; 

    }
    temp['claimOn'] = await _swapContract.methods.claimstart().call() ;


    temp['balanceA'] = parseFloat(_getTokenABalance/1e1**_getTokenADecimals).toFixed(_getTokenADecimals) ;
    temp['symbolA'] = _getTokenASymbol ;
    temp['tokenA'] = _getTokenA ;

    if(_getTokenBBalance.length > 8 ){
      temp['balanceB'] = parseFloat(_getTokenBBalance/1e1**_getTokenBDecimals).toFixed(4) ;
    }
    else{
      temp['balanceB'] = parseFloat(_getTokenBBalance/1e1**_getTokenBDecimals).toFixed(8) ;
    }


    temp['symbolB'] = _getTokenBSymbol ;
   

    temp['claimable'] = claimable ;

    setData(temp) ;
} 
  

return (
  
  
  <div className={styles.body}>
      
 
      <section id="sec-new">
       <div className="container">
           <div className="row">
           {data.claimable > 0 && 
            
            <div className="col-lg-1"></div>
           }
           {data.claimable == 0 && 
         
         <div className="col-lg-3"></div>
        }
               <div className="col-lg-5">
                   <div className="exchange-box">
                       <h3>SWAP</h3>
                       <p>Deposit {data.symbolA} to claim {data.symbolB}</p>
                       <div className="stokes-new"></div>
                       <div className="from-box-wrp leftinput">
                       <p className="t-r">Balance: {data.balanceA}</p>
                 
                       <div className="flex-box-wrp">

                           <div className="from-box-c1 leftinput">
                               <p>From <span className="depositButton ml-2 p-1 pl-3 pr-3" onClick={() => setMaxDeposit(data.balanceA)}>Max</span></p>
                               <input  onChange={handleDepositChange} value={amount} className="no-outline" placeholder="0.0" />
                           </div>
                           <div className="from-box-c1">
                           
                            <h4>{data.symbolA}</h4>
                           </div>
                       </div>
                       </div>
                
                       <div className="from-box-wrp">
                       <p className="t-r">Balance: { data.balanceB }</p>

                       <div className="flex-box-wrp">

                           <div className="from-box-c1 leftinput">
                               <p>To</p>
                               <input className="no-outline" readOnly={true} value={camount} />
                           </div>
                           <div className="from-box-c1">
                      
                            <h4>{data.symbolB}</h4>
                           </div>
                       </div>
                       </div>
                 
                        {
                        wallet.account && data.approval > 0 &&
                       <button className="mt-4" onClick={deposit}  >Deposit {data.symbolA}</button>
                        
                        }
                        {
                        wallet.account && data.approval == 0 &&
                       <button className="mt-4" onClick={() => approve(data.tokenA)}  >Approve {data.symbolA}</button>
                        
                        }
 

                   </div>
               </div>
               {data.claimable > 0 && 
               <div className="col-lg-5">
                   <div className="exchange-box">
                       <h3>Claim</h3>
                       <p>Claim {data.symbolB}</p>
                       <div className="stokes-new"></div>
                       <div className="from-box-wrp">
                           <div className="from-box-c1">
                               <p>Claimable</p>
                                
                           </div>
                           <div className="from-box-c1">
                            <p className="t-r">Balance: {data.claimable}</p>
                            <h4>{data.symbolB}</h4>
                           </div>
                       </div>
                        { data.claimOn && 
                        
                        <button className="mt-4" onClick={claimToken} >Claim {data.symbolB}</button>
                        
                        }
                        { !data.claimOn && 
                        
                          <p>Claim is not enabled. Please wait for owner to enable the claim.</p>
                        
                        }

                   </div>
               </div>
              }

               {data.claimable > 0 && 
            
               <div className="col-lg-1"></div>
              }
              {data.claimable == 0 && 
            
            <div className="col-lg-4"></div>
           }
           </div>
       </div>
   </section>

   <Modal isOpen={balanceModal} toggle={toggleBalanceModal}  centered={true}>
   
        
   <ModalBody>
   <div className="modaltext">You have insufficient amount of {data.symbolA}</div>      

   </ModalBody>
   <Button className="depositButton mr-auto ml-auto mb-5" onClick={toggleBalanceModal}>Close</Button>
    
 </Modal>
 <Modal isOpen={modal} toggle={toggleModal}  centered={true}>
   
        
   <ModalBody>
   <div className="modaltext">Transaction is processing...</div>      

   </ModalBody>
   <Button className="depositButton mr-auto ml-auto mb-5" onClick={toggleModal}>Close</Button>
    
 </Modal>

  </div>
);
}
export default SwapCard;

 
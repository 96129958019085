import React, { useState } from "react";
import { Row, Col, Container,Button,ModalHeader,ModalFooter, Modal, ModalBody } from "reactstrap";
import Navigation from "../../components/navigation/navigationPoolDetail";
import styles from "./poolDetail.module.scss";
import Mfista from "../../images/1.png"
import Mfista2 from "../../images/2.png"
import Mfista3 from "../../images/soon.png"
import TWINIMG from "../../images/twin.png"
import CRUSH from "../../images/crush.png"
import GUARD from "../../images/guard.png"
import WIZARD from "../../images/wizard.svg"
import BIRB from "../../images/birb-logo.svg"

import icon1 from "../../images/icon1.png"
import logoicon from "../../images/3.png"
import customer from "../../images/customer.png"

import Mfista4 from "../../images/4.png"
import right from "../../images/right.png"
import logo2 from "../../images/icons/tap_unlocked.svg";
import logo3 from "../../images/bca_logo.png"
import FarmCard from "./FarmCard.js"
import logo4 from "../../images/zen_logo.png"
import twinLogo from "../../images/twin.png"
import twitter from "../../images/icons/twitter.png";
import telegram from "../../images/icons/telegram.png";

import roiicon from "../../images/icons/roiicon.png";
import discord from "../../images/icons/discord.png";
import unknown from "../../images/icons/unknown.png";
import binance from "../../images/icons/binance.png";
import globe from "../../images/icons/globe.svg";
import TransactionPop from "./TransactionPop.js" ;
import TOKEN_POOL_ABI from  "../../BlockchainProvider/ABI/TOKEN_POOL.json"
import TOKEN_ABI from  "../../BlockchainProvider/ABI/TOKEN_ABI.json"
import ROUTER_ABI from  "../../BlockchainProvider/ABI/ROUTER_ABI.json"
import REVOLVER from "../../images/revolver-logo.png"
import BEAST from "../../images/beastLogo.png"
import PFY from "../../images/portify.png"
import CKT from "../../images/CakeToolLogo.png"
import LP_ABI from  "../../BlockchainProvider/ABI/LP_ABI.json"
import Web3 from "web3"
import Allocation from "../allocationPage/allocationPage";
import PoolsPage from "../poolsPage/poolsPage";
import Schedule from "../schedulePage/shedulePage";
import ProjectDetail from "../ProjectDetail/ProjectDetail";
import { useEffect } from 'react';
import {useWallet} from '@binance-chain/bsc-use-wallet'


const Pages = {
  Allocation: <Allocation />,
  Schedule: <Schedule />,
  // Pools: <PoolsPage />,
  Project_Detail : <ProjectDetail />
};
 
const POOL_CONTRACT =
[ 


    
    {address: "0xd033306f78a4EaE6afc1D53B29c0A77B65F44761", ownname : "CORE" , endTime : 1627851600000 , name: "CKT" , lp: 0, rewardToken: "CKT" , sape : 0 , rape: 0, stakeToken: "CKT" , website : "NA" , image : CKT , status: 1},
    {address: "0x19D2E6f982B6Cd81d2A715b3ba36ae0e5C870798", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT" , lp: 0, rewardToken: "CKT" , sape : 0 , rape: 0, stakeToken: "NAUT" , website : "NA" , image : CKT , status: 1},
    {address: "0xe29833672e4dA0C58a5caCcC31dF73D7887A984f", ownname : "CORE" , endTime : 1627851600000 , name: "CKT LP" , lp: 1, rewardToken: "CKT" , sape : 0 , rape: 0, stakeToken: "CKT LP" , website : "NA" , image : CKT , status: 1},
    {address: "0xAe942b2b54d161b6550d163A7Fbb0AB72418c215", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , lp: 1, rewardToken: "CKT" , sape : 0 , rape: 0, stakeToken: "NAUT LP" , website : "NA" , image : CKT , status: 1},

    {address: "0x8d94Ed6567282dc6762505F66C50c83FF35F90Fb", ownname : "CORE" , endTime : 1627851600000 , name: "PFY LP" , lp: 1, rewardToken: "PFY" , sape : 0 , rape: 0, stakeToken: "PFY LP" , website : "NA" , image : PFY , status: 1},
    {address: "0xbc188fEE54fa8Aeec907b18CE86dA33117797397", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , lp: 1, rewardToken: "PFY" , sape : 0 , rape: 0, stakeToken: "NAUT LP" , website : "NA" , image : PFY , status: 1},
    

    {address: "0x80A7161b0F339EeaACFfC9a068FE9f217c95F506", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , lp: 1, rewardToken: "BEAST" , sape : 0 , rape: 0, stakeToken: "NAUT LP" , website : "NA" , image : BEAST , status: 0},
    {address: "0x8D5e4f03f9979a85718642DfB79DE2fb13eE2496", ownname : "CORE" , endTime : 1627851600000 , name: "BEAST LP" , lp: 1, rewardToken: "BEAST" , sape : 0 , rape: 0, stakeToken: "BEAST LP" , website : "NA" , image : BEAST , status: 0},
    {address: "0xFf0B9B2D7f94c71341277bD54eE25f417C35fE51", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT" , lp: 0, rewardToken: "BEAST" , sape : 0 , rape: 0, stakeToken: "NAUT" , website : "NA" , image : BEAST , status: 0},
    {address: "0x3dc964BAC5899c5A222bebC61B6Dc46B727F0455", ownname : "CORE" , endTime : 1627851600000 , name: "BEAST" , lp: 0, rewardToken: "BEAST" , sape : 0 , rape: 0, stakeToken: "BEAST" , website : "NA" , image : BEAST , status: 0},

    
    {address: "0x67DED86738a30A0Be6864c10D66388EB872f35CD", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT" , lp: 0, rewardToken: "REVOLVER" , sape : 0 , rape: 0, stakeToken: "NAUT" , website : "NA" , image : REVOLVER , status: 0},
    {address: "0xEa57ba1fCd62543b3caBf2E15A6f81f87Af4a51E", ownname : "CORE" , endTime : 1627851600000 , name: "REVOLVER" , lp: 0, rewardToken: "REVOLVER" , sape : 0 , rape: 0, stakeToken: "REVOLVER" , website : "NA" , image : REVOLVER , status: 0},
    {address: "0xaD54089EFBd0cE1d09aeDf01202958F5e6C67d79", ownname : "CORE" , endTime : 1627851600000 , name: "REVOLVER LP" , lp: 1, rewardToken: "REVOLVER" , sape : 0 , rape: 0, stakeToken: "REVOLVER LP" , website : "NA" , image : REVOLVER , status: 0},
    {address: "0x8cfa5F83a1eAEeA605f8Cb85f072b6318A7c217d", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , lp: 1, rewardToken: "REVOLVER" , sape : 0 , rape: 0, stakeToken: "NAUT LP" , website : "NA" , image : REVOLVER , status: 0},
    {address: "0x628ba0E1Ad07585C6F5C2643fBaa75130BCA15E2", ownname : "CORE" , endTime : 1627851600000 , name: "BIRB" , lp: 0, rewardToken: "BIRB" , sape : 0 , rape: 0, stakeToken: "BIRB" , website : "NA" , image : BIRB , status: 0},
    {address: "0xf1273e0331B36E570CBF2A48e7e5c5240735DB1D", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT" , lp: 0, rewardToken: "BIRB" , sape : 0 , rape: 0, stakeToken: "NAUT" , website : "NA" , image : BIRB , status: 0},
    {address: "0xf0d2df14FD032Aa8B95f6eDf87Fa3C72540fe280", ownname : "CORE" , endTime : 1627851600000 , name: "BIRB LP" , lp: 1, rewardToken: "BIRB" , sape : 0 , rape: 0, stakeToken: "BIRB LP" , website : "NA" , image : BIRB , status: 0},
    {address: "0xf3503bC338De8e11d542f414dD6b39f80251AEDf", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , lp: 1, rewardToken: "BIRB" , sape : 0 , rape: 0, stakeToken: "NAUT LP" , website : "NA" , image : BIRB , status: 0 },

    {address: "0xA9F3daC336968aea4ea9Da9591e0a5e97F573A1b", ownname : "CORE" , endTime : 1627851600000 , name: "WIZARD" , lp: 0, rewardToken: "WIZARD" , sape : 0 , rape: 0, stakeToken: "WIZARD" , website : "NA" , image : WIZARD , status: 0},
    {address: "0xD4B6972cf3c2a48223f36546Af864Cd186Ea330e", ownname : "CORE" , endTime : 1627851600000 , name: "WIZARD LP" , lp: 1, rewardToken: "WIZARD" , sape : 0 , rape: 0, stakeToken: "WIZARD LP" , website : "NA" , image : WIZARD , status: 0},
    {address: "0x01B0F87BB5C61511e3fE2B5498C41B6A32095c31", ownname : "CORE" , endTime : 1627851600000 , name: "GUARD" , lp: 0, rewardToken: "NAUT" , sape : 0 , rape: 0, stakeToken: "GUARD" , website : "NA" , image : GUARD , status: 0},
    
    {address: "0xCd9febC07b6e7aB7cc9210b161C620FCFab10300", ownname : "CORE" , endTime : 1627851600000 , name: "GUARD" , rewardToken: "GUARD" , sape : 0 , rape: 0, lp: 0, stakeToken: "GUARD" , website : "NA" , image : GUARD , status: 0},
    {address: "0x99Bd018F275a34951CD7364Ce27BaAD3A1F792B5", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT" , rewardToken: "GUARD" , sape : 0 , rape: 0, lp: 0, stakeToken: "NAUT" , website : "NA" , image : GUARD , status: 0},
    {address: "0x5b0a93917C8b49e7C76b3A9c2F9d5ffd4878B2e0", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , rewardToken: "GUARD" , sape : 0 , rape: 0, lp: 1, stakeToken: "NAUT LP" , website : "NA" , image : GUARD , status: 0},
    {address: "0xC524eC489a23C20cF43dEa3EaDe550D8A5ba4EEf", ownname : "CORE" , endTime : 1627851600000 , name: "GUARD LP" , rewardToken: "GUARD" , sape : 0 , rape: 0, lp: 1, stakeToken: "GUARD LP" , website : "NA" , image : GUARD , status: 0},
    
    {address: "0x0b11fD05403231fa4eEE8f7Fe24522f22BbBc779", ownname : "CORE" , endTime : 1627851600000 , name: "TWIN" , rewardToken: "TWIN" , lp: 0, sape : 1 , rape: 1, stakeToken: "TWIN" , website : "NA" , image : TWINIMG , status: 0},
    // // {address: "0xFd3bFb0cfC00a724E2ca493d30C4B4796FaeA7b2", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT" , rewardToken: "TWIN" , stakeToken: "NAUT" , website : "NA" , image : TWINIMG },
    {address: "0x6BE6d35A329AF212EEf47682B2F56Cd0923784D1", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT" , rewardToken: "TWIN" , sape : 0 , rape: 1, lp: 0, stakeToken: "NAUT" , website : "NA" , image : TWINIMG , status: 0},
    {address: "0xEDf9CF30259a585660C43802C9B14958E869C9f9", ownname : "CORE" , endTime : 1627851600000 , name: "TWIN LP" , rewardToken: "TWIN" , sape : 1 , rape: 1, lp: 1, stakeToken: "TWIN LP" , website : "NA" , image : TWINIMG , status: 0},
    {address: "0x2d4c435C5ed9A2AA9bC59A41666564317c5FAf58", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , rewardToken: "TWIN" , sape : 0 , rape: 1, lp: 1, stakeToken: "NAUT LP" , website : "NA" , image : TWINIMG , status: 0},
    {address: "0xf517521c2f01Ae457a770f65ACC145E1F45eF066", ownname : "CORE" , endTime : 1627851600000 , name: "CRUSH LP" , rewardToken: "CRUSH" , sape : 1 , rape: 1, lp: 1, stakeToken: "CRUSH LP" , website : "NA" , image : CRUSH , status: 1},
    {address: "0x4B3891F2187bC08F1b5b6Ad56d9A8df03B6bf441", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , rewardToken: "CRUSH" , sape : 0 , rape: 1, lp: 1, stakeToken: "NAUT LP" , website : "NA" , image : CRUSH , status: 1},
    {address: "0x727808E7cE3D85d78C20B1BC5E5D18c4D06bA84D", ownname : "NATIVE" , endTime : 1627851600000 , name: "SOON" , rewardToken: "SOON" , lp: 0, sape : 0 , rape: 0 , stakeToken: "SOON" , website : "NA" , image : Mfista3  , status: 0},
    {address: "0xC410F3dA78DE8B5d4151B2196287fda3222E03D6", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT" , rewardToken: "SOON" , lp: 0 , sape : 0 , rape: 0, stakeToken: "NAUT" , website : "NA" , image : Mfista3, status: 0},
    {address: "0x55CC8276197C69833461Bd2167237775869208D1", ownname : "CORE" , endTime : 1627851600000 , name: "NAUT LP" , lp: 1 , rewardToken: "SOON" , sape : 0 , rape: 0, stakeToken: "NAUT LP" , website : "NA" , image : Mfista3 , status: 0},
    {address: "0xFe3820b24d0665969E8Dc614099EA1d967C63A8C", ownname : "CORE" , endTime : 1627851600000 , name: "SOON LP" , lp: 1, rewardToken: "SOON" , sape : 0 , rape: 0, stakeToken: "SOON LP" , website : "NA" , image : Mfista3 , status: 0},
   

     
];
 


const Fista = () => {
  const data = JSON.parse(sessionStorage.getItem('pool'));
  const [isOpen, setOpen]= useState(false);
  const [isConnected, setConnected]= useState(false);
  const [modal, setModal] = useState(false);
  const [depositmodal, setDepositmodal] = useState(false);

  const [oneDayRoi, setOneDayRoi] = useState(0);
  const [sevenDayRoi, setSevenDayRoi] = useState(0);
  const [thirtyDayRoi, setThirtyDayRoi] = useState(0);
  const [yearRoi, setyearRoi] = useState(0);
  
  const [oneDay, setOneDay] = useState(0);
  const [sevenDay, setSevenDay] = useState(0);
  const [thirtyDay, setThirtyDay] = useState(0);
  const [year, setyear] = useState(0);

  const [link, setLink] = useState('0');
//   const [web3Provider, setweb3Provider] = useState(null);
  let web3Provider  = window.ethereum ; 
   

  

  const [roimodal, setRoimodal] = useState(false);
  const [withdrawmodal, setWithdrawmodal] = useState(false);
  const [nowithdrawmodal, setnoWithdrawmodal] = useState(false);
  const [noclaimRewardmodal, setnoclaimRewardmodal] = useState(false);
  
  const [details, setShowDetails] = useState([]);
  
  const [symbol, setSymbol] = useState('');
  const [rsymbol, setrSymbol] = useState('');
  
  const [balance, setBalance] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [currentContract, setCurrentContract] = useState('');
  const [wcurrentContract, setWCurrentContract] = useState('');
  const [wsamount, setwsamount] = useState('');
  const [damount, setdAmount] = useState('');
  
  const [wsymbol, setWSymbol] = useState('');
  const [wbalance, setWBalance] = useState(0);
//   var poolsArray = [] ;
  const [poolsArray, setPoolsArray]= useState([]);
  const [tvl, setTVL]= useState(0);
  
//   const [pools, setPools]= useState([]);
  const [tpop, setTPop]= useState(false);
const [show, setShow] = useState(false);
const [depositError, setDepositError] = useState(false);
const [withdrawError, setWithdrawError] = useState(false);
  
const [selectedPage, setSelectedPage] = useState("Project_Detail");
 

const wallet = useWallet();

 
 

useEffect(()=>{

        
    'use strict';
    
function Tabs() {
var bindAll = function() {
var menuElements = document.querySelectorAll('[data-tab]');
for(var i = 0; i < menuElements.length ; i++) {
  menuElements[i].addEventListener('click', change, false);
}
}

var clear = function() {
var menuElements = document.querySelectorAll('[data-tab]');
for(var i = 0; i < menuElements.length ; i++) {
  menuElements[i].classList.remove('active');
  var id = menuElements[i].getAttribute('data-tab');
  document.getElementById(id).classList.remove('active');
}
}

var change = function(e) {
clear();
e.target.classList.add('active');
var id = e.currentTarget.getAttribute('data-tab');
document.getElementById(id).classList.add('active');
}

bindAll();
}

 


var connectTabs = new Tabs();






},[])


 
useEffect(() => {
    if(window.ethereum){
        web3Provider  = window.ethereum;
      }
      else{
        web3Provider = new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/')
       
      }

    initContracts() ;
   
},[wallet.account])

const toggle = () => setModal(!modal);
const depositToggle = () => setDepositmodal(!depositmodal);
const withdrawToggle = () => setWithdrawmodal(!withdrawmodal);
const nowithdrawToggle = () => setnoWithdrawmodal(!nowithdrawmodal);
const roimodalToggle = () => setRoimodal(!roimodal);
const noclaimRewardToggle = () => setnoclaimRewardmodal(!noclaimRewardmodal);

const handleDepositChange = (e) => {
    setDepositAmount(e.target.value) ;
    setdAmount(e.target.value) ;

}
const handleWithdrawChange = (e) => {
    setWithdrawAmount(e.target.value)
    setwsamount(e.target.value)
}
const toggleDetails = (box) => {

    const detailarr = details.slice()  ;

    const index = detailarr.indexOf(box) ;

    if(index >= 0) {
        detailarr.splice(index, 1) ;
        setShowDetails(detailarr);
    }
    else{
        detailarr.push(box) ;
        setShowDetails(detailarr);

    }
}



const getVolume = async (_index) => {
    var v = POOL_CONTRACT[_index] ;  
    let _web3 = new Web3(web3Provider);
    const BNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'; // BNB or another token
    const BUSD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' ; //BUSD
    let _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,v.address);
  
    let totalStaked = await _tokenPoolContract.methods.totalStaked().call() ;
    let stakeTokenAddress = await _tokenPoolContract.methods.stakeToken().call() ;
    let _tokenContract = new _web3.eth.Contract(TOKEN_ABI,stakeTokenAddress);
    let stotalDecimals = await _tokenContract.methods.decimals().call() ;
 


    let RROUTER_ADDRESS = null ; 
        let SROUTER_ADDRESS = null ; 
        if(v.rape == 0){
            RROUTER_ADDRESS = '0x10ed43c718714eb63d5aa57b78b54704e256024e';
        }
        else if(v.rape == 1) {
            RROUTER_ADDRESS = '0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7';

        }

        if(v.sape == 0){
            SROUTER_ADDRESS = '0x10ed43c718714eb63d5aa57b78b54704e256024e';
        }
        else if(v.sape == 1){
            SROUTER_ADDRESS = '0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7';

        }
        let _amountUSD = 1 * (10 ** 18 ); 

        let _routerContractS = new _web3.eth.Contract(ROUTER_ABI, SROUTER_ADDRESS);

        let _resultUSDS = await _routerContractS.methods.getAmountsOut(_amountUSD+'', [BNB, BUSD]).call();
        let BNBUsdS = _resultUSDS[1] / (10 ** 18);
 
        let _routerContractR = new _web3.eth.Contract(ROUTER_ABI, RROUTER_ADDRESS);
 
        let _resultUSDR = await _routerContractR.methods.getAmountsOut(_amountUSD+'', [BNB, BUSD]).call();
        let BNBUsdR = _resultUSDR[1] / (10 ** 18);

    let apr  = 0 ;
    let sttokenpool = 0 ;
    if(totalStaked > 0 ){
        let _stokenPrice = 0 ;
        let _tokenPrice = 0 ;

        if(v.lp == 1){
            console.log("It's LP");

            let _tokenLpContract = new _web3.eth.Contract(LP_ABI,stakeTokenAddress);
            let _tokenA = await  _tokenLpContract.methods.token0().call() ;
            let _tokenB = await  _tokenLpContract.methods.token1().call() ;
            let _tokenSupply = await  _tokenLpContract.methods.getReserves().call() ;

            let _tokenAContract = new _web3.eth.Contract(TOKEN_ABI,_tokenA);
            let _tokenBContract = new _web3.eth.Contract(TOKEN_ABI,_tokenB);
            let _tokenAdecimals = await _tokenAContract.methods.decimals().call() ;
            let _tokenBdecimals = await _tokenBContract.methods.decimals().call() ;

            // let _tokenBSupply = await  _tokenLpContract.methods.token1().call() ;
            // let _tokenADetails = await fetch('https://api.dex.guru/v1/tokens/'+_tokenA+'-bsc').then((data) => data.json());
            // let _tokenBDetails = await fetch('https://api.dex.guru/v1/tokens/'+_tokenB+'-bsc').then((data) => data.json());
            let _amountA = 1 * (10 ** _tokenAdecimals ); 
            let _amountB = 1 * (10 ** _tokenBdecimals ); 
            // console.log("Getting LP Price");
            
            let _tokenBpriceUSD = BNBUsdS ;
            if(_tokenB != BNB){
                let _resultB = await _routerContractS.methods.getAmountsOut(_amountB+'', [_tokenB, BNB]).call();
                _tokenBpriceUSD = _resultB[1] / (10 ** 18); // price of 1 CAKE in BUSD
                _tokenBpriceUSD = _tokenBpriceUSD * BNBUsdS ;

            }

            let _tokenApriceUSD = BNBUsdS ;
            if(_tokenA != BNB){
            let _resultA = await _routerContractS.methods.getAmountsOut(_amountA+'', [_tokenA, BNB]).call();

            _tokenApriceUSD = _resultA[1] / (10 ** 18); // price of 1 CAKE in BUSD
            _tokenApriceUSD = _tokenApriceUSD * BNBUsdS ;

            }
            // console.log("Token A  Price: ", _tokenApriceUSD);
            // console.log("Token B  Price: ", _tokenBpriceUSD);
            
            let _tokenAsupply = _tokenSupply._reserve0/1e1**_tokenAdecimals ;
            let _tokenBsupply = _tokenSupply._reserve1/1e1**_tokenBdecimals ;

            let _totalValue = parseFloat(_tokenApriceUSD*_tokenAsupply) + parseFloat(_tokenBpriceUSD*_tokenBsupply) ;
            // console.log("Total "+v.name+" Value: ", _totalValue);

            let _totalSupply = await  _tokenLpContract.methods.totalSupply().call() ; 
            let _lpDecimals = await  _tokenLpContract.methods.decimals().call() ; 
            console.log("Total "+v.name+" Supply: ", _totalSupply);
            _totalSupply = _totalSupply/1e1**_lpDecimals ;
            _stokenPrice = parseFloat(_totalValue/_totalSupply)
            console.log("Total "+v.name+" Price: ", _stokenPrice);
            
    
    
            }
            else{
                console.log("Getting S Price");
                // _stokenPrice = 1 ;
                let _amountS = 1 * (10 ** stotalDecimals ); 
                let _resultS = await _routerContractS.methods.getAmountsOut(_amountS+'', [stakeTokenAddress, BNB]).call();
                _stokenPrice = _resultS[1] / (10 ** 18); // price of 1 CAKE in BUSD
                console.log("StakeP "+v.stakeToken+": "+_stokenPrice)

                _stokenPrice = _stokenPrice * BNBUsdS ;
                // const _stokenDetails = await fetch('https://api.dex.guru/v1/tokens/'+temp['stakeTokenAddress']+'-bsc').then((data) => data.json());
                console.log("StakeP "+v.stakeToken+": "+_stokenPrice)
                // _stokenPrice  = _stokenDetails.priceUSD ;
                // _stokenPrice  = _stokenDetails.priceUSD ;

            }
            
           
       
        sttokenpool = parseFloat(_stokenPrice*totalStaked/1e1**stotalDecimals).toFixed() ;
        return sttokenpool ;

    }
    else{
        return sttokenpool ;

    }
} 




const initContracts  = async () =>  {

    // setPoolsArray([]) ;

    let _web3 = new Web3(web3Provider);
    console.log(wallet) ;
    let ltemparr =  [] ;
    // poolsArray = [] ;
        // setPools([]) ;
    var pools = [] ;
     // let _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,v.address);
    const BNB = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'; // BNB or another token
    const BUSD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56' ; //BUSD
    // let _routerContractMain = new _web3.eth.Contract(ROUTER_ABI, ROUTER_ADDRESS);
    let _totalVolume =  0 ;


        // config.POOL_CONTRACT.forEach(initContracts);
        for(let i = 0 ; i < POOL_CONTRACT.length ; i++ ){
        var v = POOL_CONTRACT[i] ;  
        

        let temp = {} ;
        temp['name'] = v.name ;  
        temp['address'] = v.address ;  
        temp['userStaked'] = 0 ;
        temp['userReward'] = 0 ;
        temp['rewardToken'] = v.rewardToken ;
        temp['stakeToken'] = v.stakeToken ;
        temp['ownname'] = v.ownname ;
 
        
        temp['endTime'] = "0h : 0m : 0s" ;
        
        temp['approval'] = 0 ;

        temp['website'] = v.website ;
        temp['image'] = v.image ;

        temp['stakeTokenAddress'] = v.address ;
        temp['rewardTokenAddress'] = v.address ;

         
        temp['totalStaked'] = 0 ;

        // let totalStaked = 1e18 ;
        temp['apr'] = 0 ;

        
        temp['stokenprice'] = 0 ;
        
        console.log("Getting R Price");
 
        temp['rtokenprice'] = 0 ;

 
        temp['apr'] = 0 ;

       

        
        var account = wallet.account ;
        temp['user'] = account;
        if(v.status == 1){
            let _volume = await getVolume(i) ;
            _totalVolume += parseInt(_volume) ;    
        }
        ltemparr.push(temp) ;
 

        if(ltemparr.length == POOL_CONTRACT.length){
            setPoolsArray(ltemparr);
            setTVL(_totalVolume);
        console.log("Total VolumeA Locked", _totalVolume);

        }

    }
 
  

  }
 
 

async function isApproved(contractAddress,tokenAddress){
    let _web3 = new Web3(web3Provider);
    const _tokenContract = new _web3.eth.Contract(TOKEN_ABI,tokenAddress);
    var approval = await _tokenContract.methods.allowance(wallet.account,contractAddress).call() ; 
    console.log(approval); 
    if(approval > 0){
        return true  ;
    }
    else{
        return false  ;

    }

}


const calculateCakeEarnedPerThousandDollars = (numberOfDays, farmApy, cakePrice) => {
    // Everything here is worked out relative to a year, with the asset compounding daily
    const timesCompounded = 365
    //   We use decimal values rather than % in the math for both APY and the number of days being calculates as a proportion of the year
    const apyAsDecimal = farmApy / 100
    const daysAsDecimalOfYear = numberOfDays / timesCompounded
    //   Calculate the starting CAKE balance with a dollar balance of $1000.
    const principal = 1000 / cakePrice
    console.log(cakePrice);
  
    // This is a translation of the typical mathematical compounding APY formula. Details here: https://www.calculatorsoup.com/calculators/financial/compound-interest-calculator.php
    const finalAmount = principal * (1 + apyAsDecimal / timesCompounded) ** (timesCompounded * daysAsDecimalOfYear)
  
    // To get the cake earned, deduct the amount after compounding (finalAmount) from the starting CAKE balance (principal)
    const interestEarned = finalAmount - principal
    return parseFloat(interestEarned).toFixed(2)
  }
  
  const apyModalRoi = (amountEarned, amountInvested) => {
    const percentage = (amountEarned / amountInvested) * 100
    return percentage.toFixed(2)
  }


function opennoclaimRewardModal(){
    setnoclaimRewardmodal(!noclaimRewardmodal)

}

function opennoWithdrawModal(){
    setnoWithdrawmodal(!nowithdrawmodal)

}

function getStaked(contractAddress){
    let _totalStaked = 0 
    let _web3 = new Web3(web3Provider);
    const _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,contractAddress);
    _tokenPoolContract.methods.totalStaked().call().then(function(response){
        console.log("Total Staked" , response)
        _totalStaked = response/1e18
        return _totalStaked ;

    }) ;  
}

async function setMaxWIthdraw(){

    // let _web3 = new Web3(web3Provider);
    // const _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,wcurrentContract);
    // let userInfo = await _tokenPoolContract.methods.userInfo(wallet.account).call() ;  
    // let _staketoken = await _tokenPoolContract.methods.stakeToken().call() ;  
    // const _tokenContract = new _web3.eth.Contract(TOKEN_ABI,_staketoken);
 

    // const decimals = await _tokenContract.methods.decimals().call();
    // const wbalance  = parseFloat(userInfo.amount/1e1**decimals).toFixed(4) ; 

    setwsamount(wbalance)
    setWithdrawAmount(wbalance)
}

async function openWithdrawModal(contractAddress,tokenAddress){
    setWithdrawmodal(!withdrawmodal)
    setWithdrawError(false);
    setwsamount('')
    setWithdrawAmount('')
 

    let _web3 = new Web3(web3Provider);
    const _tokenContract = new _web3.eth.Contract(TOKEN_ABI,tokenAddress);
    const _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,contractAddress);
    const wsymbol = await _tokenContract.methods.symbol().call();
    const decimals = await _tokenContract.methods.decimals().call();
    let userInfo = await _tokenPoolContract.methods.userInfo(wallet.account).call() ;  

    
    setWSymbol(wsymbol);
    setWCurrentContract(contractAddress);

    if(userInfo.amount < 1e1**decimals){
        const wbalance  = parseFloat(userInfo.amount/1e1**decimals)  ; 
        setWBalance(wbalance);
    }
    else{
        const wbalance  = parseFloat(userInfo.amount/1e1**decimals)  ; 
        setWBalance(wbalance);
    }

} 


async function openRoiModal(contractAddress,rtoken,stoken,rtokenprice,stokenprice){
    let _web3 = new Web3(web3Provider);
    const _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,contractAddress);
    const _stokenContract = new _web3.eth.Contract(TOKEN_ABI,stoken);
    const _rtokenContract = new _web3.eth.Contract(TOKEN_ABI,rtoken);

    const symbol = await _stokenContract.methods.symbol().call();
    const rsymbol = await _rtokenContract.methods.symbol().call();
     
    let stotalDecimals = await _stokenContract.methods.decimals().call() ;
    let rtotalDecimals = await _rtokenContract.methods.decimals().call() ;
    let totalStaked = await _tokenPoolContract.methods.totalStaked().call() ;
    
    // temp['rewardTokenAddress'] = await _tokenPoolContract.methods.rewardToken().call() ;
    // const _tokenDetails = await fetch('https://api.dex.guru/v1/tokens/'+rtoken+'-bsc').then((data) => data.json());
    // let _tokenDetails = tokenDetails.json() ;
    let _tokenPrice  = rtokenprice ;
    
    let _rewardperblock = await _tokenPoolContract.methods.rewardPerBlock().call() ;

    const _stokenDetails = await fetch('https://api.dex.guru/v1/tokens/'+stoken+'-bsc').then((data) => data.json());
    
    let _stokenPrice  = stokenprice ;
    let sttokenpool = _stokenPrice*totalStaked ;


    let earnPerYear = 10512000 * _rewardperblock * _tokenPrice  ; 
    earnPerYear = parseFloat((earnPerYear/1e1**rtotalDecimals)/(sttokenpool/1e1**stotalDecimals)).toFixed() ;
    console.log(_tokenPrice);
    let oneDay = calculateCakeEarnedPerThousandDollars(1 , earnPerYear*100 , _tokenPrice)
    let sevenDay = calculateCakeEarnedPerThousandDollars(7 , earnPerYear*100 , _tokenPrice)
    let thirtyDay = calculateCakeEarnedPerThousandDollars(30 , earnPerYear*100 , _tokenPrice)
    let year = calculateCakeEarnedPerThousandDollars(365 , earnPerYear*100 , _tokenPrice)
    console.log(oneDay);

    let oneDayRoi =   apyModalRoi(oneDay,1000/_tokenPrice);
    let sevenDayRoi =   apyModalRoi(sevenDay,1000/_tokenPrice);
    let thirtyDayRoi =   apyModalRoi(thirtyDay,1000/_tokenPrice);
    let yearRoi =   apyModalRoi(year,1000/_tokenPrice);
    
    
    setRoimodal(!roimodal)

    setOneDay(oneDay);
    setSevenDay(sevenDay);
    setThirtyDay(thirtyDay);
    setyear(year);


    setOneDayRoi(oneDayRoi);
    setSevenDayRoi(sevenDayRoi);
    setThirtyDayRoi(thirtyDayRoi);
    setyearRoi(yearRoi);
    setrSymbol(rsymbol);
    setSymbol(symbol);
    setLink("https://dex.apeswap.finance/#/swap?outputCurrency="+stoken)
}

async function setMaxDeposit(){

 
    setdAmount(balance)
    setDepositAmount(balance)
}

async function openDepositModal(contractAddress, tokenAddress){
    setDepositmodal(!depositmodal)
    setDepositError(false);
    setdAmount('')
    setDepositAmount('')
    let _web3 = new Web3(web3Provider);
    const _tokenContract = new _web3.eth.Contract(TOKEN_ABI,tokenAddress);
    const symbol = await _tokenContract.methods.symbol().call();
    const balance = await _tokenContract.methods.balanceOf(wallet.account).call();
    const decimals = await _tokenContract.methods.decimals().call();
    setSymbol(symbol);
    setCurrentContract(contractAddress);
    if(balance < 1e1**decimals){
        setBalance(parseFloat(balance/1e1**decimals) );
    }
    else{
        setBalance(parseFloat(balance/1e1**decimals) );

    }
}

async function claimReward(currentContract){
    let _web3 = new Web3(web3Provider);
    setModal(!modal);
    console.log(wallet.account);
    const _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,currentContract);
    _tokenPoolContract.methods.withdraw(0).send({
        from: wallet.account
    }).on('receipt', function(receipt){
        setModal(modal);
        initContracts() ;
    }).on('error', function(error){
        console.log(error);
        setModal(modal);

    })


}



async function withdrawToken(){
    setWithdrawError(false);
    if(wbalance <= 0 ){
        setWithdrawError('Insufficient Staked Balance. Please enter amount smaller than or equal to your staked balance.');
        return false;
    }

    if(withdrawAmount <= 0 || withdrawAmount == ""){
        setWithdrawError('Invalid Withdraw Amount. Please enter a valid amount greater than 0.');
        return false;
    }


    let _web3 = new Web3(web3Provider);

 
    const _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,wcurrentContract);

    let _staketoken = await _tokenPoolContract.methods.stakeToken().call() ;  

    const _tokenContract = new _web3.eth.Contract(TOKEN_ABI,_staketoken);

    let _decimals = await _tokenContract.methods.decimals().call() ;  
    // _decimals = 1e1**_decimals ;
 
    let _amount = parseFloat(withdrawAmount) ;
    // let _amount = parseFloat(depositAmount).toFixed() + parseFloat(depositAmount*0.4).toFixed();
    // let _amount = parseFloat(depositAmount*_decimals).toFixed() ;

    if(_decimals == 18){
        _amount = _web3.utils.toWei(_amount.toString()) ;
    }
    else{
        // withdrawAmount
        _amount = parseFloat(_amount*1e1**_decimals).toFixed() ;
    }

    // let _amount = parseFloat(depositAmount).toFixed() + parseFloat(depositAmount*0.4).toFixed();
    // let _amount = _web3.utils.toWei(withdrawAmount.toString(), _web3.utils.toBN(_decimals) );

    console.log(_amount);

    setWithdrawmodal(false)
    setModal(!modal);
    _tokenPoolContract.methods.withdraw(_amount).send({
        from: wallet.account
    }).on('receipt', function(receipt){
        setModal(modal);
        initContracts() ;
    }).on('error', function(receipt){
        setModal(modal);

    })
    
}

async function depositToken(){
    setDepositError(false);
    if(balance <= 0 ){
        setDepositError('Insufficient Balance. Please fund your wallet with some '+symbol+' Token and try again.');
        return false;
    }

    if(depositAmount <= 0 || depositAmount == ""){
        setDepositError('Invalid Deposit Amount. Please enter a valid amount greater than 0.');
        return false;
    }


    let _web3 = new Web3(web3Provider);
    const _tokenPoolContract = new _web3.eth.Contract(TOKEN_POOL_ABI,currentContract);

    let _staketoken = await _tokenPoolContract.methods.stakeToken().call() ;  

    const _tokenContract = new _web3.eth.Contract(TOKEN_ABI,_staketoken);

    let _decimals = await _tokenContract.methods.decimals().call() ;   
    // _decimals = 1e1**_decimals ;
    let _amount = parseFloat(depositAmount) ;
    // console.log(_amount);
    // _amount = parseInt(_amount) ;
    // let _amount = parseFloat(depositAmount).toFixed() + parseFloat(depositAmount*0.4).toFixed();
    // _amount = _web3.utils.toWei(_amount.toString()) ;
    if(_decimals == 18){
        _amount = _web3.utils.toWei(_amount.toString()) ;
    }
    else{
        _amount = parseFloat(_amount*1e1**_decimals).toFixed() ;

        // _amount = _web3.utils.toBN(_amount).mul( _web3.utils.toBN(1e1**_decimals)) ;
    }

    console.log(_amount);

    setDepositmodal(false)
    setModal(!modal);
    _tokenPoolContract.methods.deposit(_amount).send({
        from: wallet.account
    }).on('receipt', function(receipt){
        setModal(modal);
        initContracts() ;
    }).on('error', function(receipt){
        setModal(modal);

    })
    
}

async function approveToken(contractAddress, tokenAddress){
    let _web3 = new Web3(web3Provider);
    console.log(contractAddress,tokenAddress);
    setShow(true);
    setModal(!modal);
    // document.getElementById("exampleModalCenter").modal('show')
    const _tokenContract = new _web3.eth.Contract(TOKEN_ABI,tokenAddress);
    const _amount = _web3.utils.toWei('100000000') ;
    _tokenContract.methods.approve(contractAddress,_amount).send({from: wallet.account}).on('receipt', function(receipt){
        initContracts(); 
         setModal(modal);

    })
  
    .on('error', function(error, receipt) {
    setModal(modal);
        
    });
       
}




function submitApprove(){
  setOpen(false);
}



return (
  
  
  <div className={styles.body}>
      
   <section className="animated-bg">
   
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="fista-wrp" >
                        <div className="fista-comtent">
                            <img src={Mfista} className="mfista" />
                            <p className="fista-p">POOLS</p>
                            <p className="paragraph">Stake NAUT to earn new tokens.<br></br>
                                You can unstake at any time.<br></br>
                                Rewards are calculated per block.</p>
                        </div>
                        <div className="fista-img">
                            <img src={Mfista2} className="Mfista2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </section>
   <section id="secod-sec">
   <div className="container">
           <div className="wrp-inactive">
               <div className="child-inactive1">
                   <button data-tab="orange" className="b-nav-tab active metric-s">Active</button>
               </div>
               <div className="child-inactive1 mart-f">
                   <button data-tab="green" className="b-nav-tab metric-s">Inactive</button>
               </div>
                {
                    tvl >= 1e6 &&
                    <button className="sqrBx">Total Value Locked ${parseFloat(tvl/1e6).toFixed(2)}m</button>               

                }
                {
                    tvl < 1e6 &&
                    <button className="sqrBx">Total Value Locked ${parseFloat(tvl/1e3).toFixed()}k</button>               

                }
             
           </div>
       </div>
       <div className="container">
           <div id="orange" className="b-tab active">
           <div className="row">
           {POOL_CONTRACT.length > 0 && POOL_CONTRACT.map((value, index) => {
               if(value.status == 1){
              return  <FarmCard index={index} status={value.status}
                                     
                        />
               }
               
                 })}
              <div className="col-lg-4">
                    <div className="project-box">
                        <h3>Your Project <span><img src={icon1} /></span></h3>
                        <div className="flower-img"><img src={logoicon} /></div>
                        <h4>???</h4>
                        <p>Create a pool for your token</p>
                        <a target="_blank" href="https://forms.gle/VUGQfumn3ThjTbfd6">Apply Now</a>
                        <div className="apr-wrp">
                            <div className="apr-content">
                                <p>APR:</p>
                            </div>
                            <div className="apr-content2">
                            <p>??</p>
                            </div>
                        </div>
                        <div className="stakes-wrp">
                            <div className="stakes-content">
                                <p><span><img src={icon1} className="icon-img" /></span> Your Stake</p>
                            </div>
                            <div className="stakes-content2">
                                <p>??? NAUT</p>
                            </div>
                        </div>
                        <a target="_blank" href="https://t.me/astronaut_bsc"><img src={customer} className="customer" /> Community</a>
                    </div>
                    </div>

           </div>
           </div>
           <div id="green" className="b-tab">
           <div className="row">

           {POOL_CONTRACT.length > 0 && POOL_CONTRACT.map((value, index) => {
               if(value.status == 0){
              return  <FarmCard index={index} status={value.status}
                                     
                        />
               }
               
                 })}
           </div>
           </div>
       </div>
   </section>
   <Modal isOpen={modal} toggle={toggle}  centered={true}>
   
        
        <ModalBody>
        <div className="modaltext">Transaction is Processing...</div>      

        </ModalBody>
        <Button className="depositButton mr-auto ml-auto mb-5" onClick={toggle}>Close</Button>
         
      </Modal>
      
      <Modal isOpen={noclaimRewardmodal} toggle={noclaimRewardToggle}  centered={true}>
  
        
        <ModalBody>
        <div className="modaltext">You have not earned any reward in this pool yet.</div>        
        </ModalBody>
        <Button className="depositButton mr-auto ml-auto mb-5" onClick={noclaimRewardToggle}>Close</Button>
         
      </Modal>
      <Modal isOpen={nowithdrawmodal} toggle={nowithdrawToggle}  centered={true}>
 
        <ModalBody>
        <div className="modaltext">You have not staked in this pool yet.</div>        
        </ModalBody>
        <Button className="depositButton mr-auto ml-auto mb-5" onClick={nowithdrawToggle}>Close</Button>

         
      </Modal>

      <Modal isOpen={roimodal} toggle={roimodalToggle}  centered={true}>
      <ModalHeader toggle={roimodalToggle}><span className="ml-4 roititle">ROI</span></ModalHeader>
        <ModalBody>
        <div className="roiarea">
                 <table>
                        <tr>
                         <th width="40%">
                            Timeframe
                         </th>
                         <th width="30%">
                            ROI
                         </th>
                         <th width="30%">
                            {rsymbol} per 1000
                         </th>
                         </tr>
                     <tr>
                     <td colSpan="3">
                     </td>
                        </tr>
                        <tr>
                     <td colSpan="3">
                     </td>
                        </tr>
                     <tr>
                         <td>
                            1d
                         </td>
                         <td>
                            {oneDayRoi}%
                         </td>
                         <td>
                            {oneDay}
                         </td>
                         </tr>
                         <tr>
                         <td>
                            7d
                         </td>
                         <td>
                            {sevenDayRoi}%
                         </td>
                         <td>
                            {sevenDay}
                         </td>
                         </tr>
                         <tr>
                         <td>
                            30d
                         </td>
                         <td>
                            {thirtyDayRoi}%
                         </td>
                         <td>
                            {thirtyDay}
                         </td>
                         </tr>
                         <tr>
                         <td>
                            365d(annual)
                         </td>
                         <td>
                            {yearRoi} %
                         </td>
                         <td>
                            {year}
                         </td>
                         </tr>
                 </table>
                 <p>Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</p>
                 <p className="text-center"><a target="_blank" href={link} >Get {symbol}</a></p>
            </div>        
        </ModalBody>
         
      </Modal>

      <Modal isOpen={withdrawmodal} toggle={withdrawToggle}  centered={true}>
       
      <ModalBody>
              
         <div className="moveRight">
             
             <span> 
                Your Staked<br />
                {wbalance} {wsymbol}
             </span>
         </div>
        <label>Enter Withdraw Amount <span className="depositButton ml-2 p-2" onClick={setMaxWIthdraw}>Max</span> </label>
        <input className="form-control" onChange={handleWithdrawChange} type="text"  value={wsamount} />
        {
            withdrawError &&
            <span className="error">{withdrawError}</span>
        }
      </ModalBody>
      <ModalFooter>
        <Button className="depositButton mr-3" onClick={withdrawToken}>Withdraw</Button>{' '}
        <Button className="depositButton" onClick={withdrawToggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
      <Modal isOpen={depositmodal} toggle={depositToggle}  centered={true}>
   
      
        <ModalBody>
                
           <div className="moveRight">
               
               <span> 
                  Your Balance<br />
                  {balance} {symbol}
               </span>
           </div>
          <label>Enter Deposit Amount <span className="depositButton ml-2 p-2" onClick={setMaxDeposit}>Max</span></label>
          <input className="form-control" onChange={handleDepositChange} type="text" value={damount} />
          {
              depositError &&
              <span className="error">{depositError}</span>
          }
        </ModalBody>
        <ModalFooter>
          <Button className="depositButton mr-3" onClick={depositToken}>Deposit</Button>{' '}
          <Button className="depositButton" onClick={depositToggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
  </div>
);
}
export default Fista;

 
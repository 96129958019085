module.exports={
    // STAKING_CONTRACT :"0x4c7288EE6257166eF0a2D04cb18dbED2Df259f5b",
    NAUT_CONTRACT:"0x05b339b0a346bf01f851dde47a5d485c34fe220c",
    // ICO_CONTRACT:"0xCa79d5aa5eb2097326EB918FfAa9ceB895c61Dc5",
    BUSD_CONTRACT:"0xe9e7cea3dedca5984780bafc599bd69add087d56",
    // LOCKED_DAYS : 1*24*60*60, //days in second
    // CHAIN_ID:56 // use 56 for mainnet
      
    STAKING_CONTRACT :"0xf0319eD6E918f8167f53Bc12151Dc989025516D0",
    // NAUT_CONTRACT:"0xf0319ed6e918f8167f53bc12151dc989025516d0",
    ICO_CONTRACT:"0xaa7e9de8f79f20a49b620ba9f38f541c3da5396e",
    // BUSD_CONTRACT:"0x8301f2213c0eed49a7e28ae4c3e91722919b8b47",
    FCFS_CONTRACT:"0x89c8843ac4b6699ce51a06f1883f8a1e93bce6b4",
    LOCKED_DAYS : 1*24*60*60, //days in second
    CHAIN_ID:56 // use 56 for mainnet

}